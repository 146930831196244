import React, { useState } from "react";

function TriedBefore({ selectLebel, setFormData, formData, changeStep }) {
  const [flag, setFlag] = useState(false);
  const [inputVal, setInputVal] = useState("");
  function handelNextStep() {
    setFlag(true);
    if (inputVal !== "") {
      setFormData({
        ...formData,
        triedAnyPrpgramBefore: inputVal,
      });
      changeStep(1);
    }
  }

  return (
    <div className="changeDiv">
      <h2>
        11. Have you tried any specific diets or weight loss programs in the
        past?
      </h2>
      <p>(select one)</p>
      <>
        <input
          type="radio"
          name="triedBefore"
          id="triedBefore1"
          value="yes"
          onChange={(e) => setInputVal(e.target.value)}
        />
        <label htmlFor="triedBefore1" onClick={(e) => selectLebel(e.target)}>
          yes
        </label>
        <input
          type="radio"
          name="triedBefore"
          id="triedBefore2"
          value="no"
          onChange={(e) => setInputVal(e.target.value)}
        />
        <label htmlFor="triedBefore2" onClick={(e) => selectLebel(e.target)}>
          no
        </label>
      </>

      {!document.querySelector(".getprogram input:checked") && flag && (
        <p className="error">
          please select one to create a good program for you
        </p>
      )}
      <div className="buttons">
        <button className="backbutton" onClick={() => changeStep(-1)}>
          Back
        </button>
        <button className="nextbutton" onClick={() => handelNextStep()}>
          NEXT
        </button>
      </div>
    </div>
  );
}

export default TriedBefore;
