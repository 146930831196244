import React from "react";

function GetCalorie({ formData, changeStep }) {
  function handelNextStep() {
    changeStep(1);
  }
  const spanStyle = {
    textTransform: "capitalize",
    fontWeight: "bold",
    letterSpacing: ".7px",
    fontSize: "15px",
  };
  return (
    <div className="changeDiv">
      <h2>calculate your Body Mass Index</h2>
      <p>
        We’re happy you’re here <span style={spanStyle}>{formData.name}</span>!
      </p>
      <ol
        style={{
          width: "400px",
          textAlign: "start",
          margin: "15px auto",
          lineHeight: "1.8",
          fontWeight: "500",
          letterSpacing: ".3px",
          fontSize: "15px",
        }}
      >
        <li>
          + your gender is: <span style={spanStyle}>{formData.gender}</span>
        </li>
        <li>
          + your age is: <span style={spanStyle}>{formData.age}</span>
        </li>
        <li>
          + your tall is: <span style={spanStyle}>{formData.tall}</span>
        </li>
        <li>
          + your weight is : <span style={spanStyle}>{formData.weight}</span>
        </li>
      </ol>
      <p>
        ( make sure that this is corecte information to calculate your Body Mass
        Index )
      </p>
      <p style={{ fontWeight: "bold", color: "#196fbf", fontSize: "15px" }}>
        Let's Calculate Your Body Mass Index
      </p>
      <div className="buttons">
        <button className="backbutton" onClick={() => changeStep(-1)}>
          back
        </button>
        <button className="nextbutton" onClick={() => handelNextStep()}>
          calculate
        </button>
      </div>
    </div>
  );
}

export default GetCalorie;
