import React, { useState } from "react";

function Barriers({ selectLebel, setFormData, formData, changeStep }) {
  const [flag, setFlag] = useState(false);
  const [inputVal, setInputVal] = useState("");
  function handelNextStep() {
    setFlag(true);
    if (inputVal !== "") {
      setFormData({
        ...formData,
        barriers: inputVal,
      });
      changeStep(1);
    }
  }

  return (
    <div className="changeDiv">
      <h2>8. In the past, what have been your barriers to losing weight?</h2>
      <p>( Choose the option that's right for you. )</p>
      <>
        <input
          type="radio"
          name="barriers"
          id="barriers1"
          value="lack of time"
          onChange={(e) => setInputVal(e.target.value)}
        />
        <label htmlFor="barriers1" onClick={(e) => selectLebel(e.target)}>
          lack of time
        </label>
        <input
          type="radio"
          name="barriers"
          id="barriers2"
          value="the regimen was to hard to follow"
          onChange={(e) => setInputVal(e.target.value)}
        />
        <label htmlFor="barriers2" onClick={(e) => selectLebel(e.target)}>
          the regimen was to hard to follow
        </label>
        <input
          type="radio"
          name="barriers"
          id="barriers3"
          value="did not enjoy the food"
          onChange={(e) => setInputVal(e.target.value)}
        />
        <label htmlFor="barriers3" onClick={(e) => selectLebel(e.target)}>
          did not enjoy the food
        </label>
        <input
          type="radio"
          name="barriers"
          id="barriers4"
          value="social eating and events"
          onChange={(e) => setInputVal(e.target.value)}
        />
        <label htmlFor="barriers4" onClick={(e) => selectLebel(e.target)}>
          social eating and events
        </label>
        <input
          type="radio"
          name="barriers"
          id="barriers5"
          value="lack of progress"
          onChange={(e) => setInputVal(e.target.value)}
        />
        <label htmlFor="barriers5" onClick={(e) => selectLebel(e.target)}>
          lack of progress
        </label>
      </>

      {!document.querySelector(".getprogram input:checked") && flag && (
        <p className="error">
          please select one to create a good program for you
        </p>
      )}
      <div className="buttons">
        <button className="backbutton" onClick={() => changeStep(-1)}>
          Back
        </button>
        <button className="nextbutton" onClick={() => handelNextStep()}>
          NEXT
        </button>
      </div>
    </div>
  );
}

export default Barriers;
