import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import "./login.css";
import axios from "axios";

export default function Login() {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [flag, setFlag] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loginResponseMe, setLoginResponseMes] = useState("");

  const handelSubmite = async (e) => {
    e.preventDefault();
    setFlag(true);
    if (
      userName.length >= 2 &&
      userName.length <= 30 &&
      password.length >= 7 &&
      password.length <= 100
    ) {
      try {
        let res = await axios.post(
          `${process.env.REACT_APP_API_URL}auth/login`,
          { userName: userName, password: password },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (res.status === 200) {
          setLoginResponseMes("");
          localStorage.setItem("token", res.data.token);
          window.location = "/dashboard";
        }
      } catch (error) {
        if (error.status === 401) {
          setLoginResponseMes(error.response.data.message);
        }
      }
    }
  };
  return (
    <div className="loginPage">
      <div className="container">
        <form onSubmit={(e) => handelSubmite(e)}>
          <h1>welcome</h1>
          <hr />
          <p className="error">{loginResponseMe}</p>
          <input
            type="text"
            name="userName"
            placeholder="userName"
            onChange={(e) => setUserName(e.target.value)}
          />
          {flag && (userName.length <= 2 || userName.length >= 30) && (
            <p className="error">this userName is invalid</p>
          )}
          <input
            type={showPassword ? "text" : "password"}
            name="password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
          {flag && (password.length <= 7 || password.length >= 100) && (
            <p className="error">this password is invalid</p>
          )}
          <div className="showPassword">
            <input
              type="checkbox"
              id="showPassword"
              onChange={() => setShowPassword((prev) => !prev)}
            />
            <label htmlFor="showPassword">show password</label>
          </div>
          <button type="submit">login</button>
        </form>
      </div>
    </div>
  );
}
