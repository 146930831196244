import React, { useEffect, useState } from "react";

function FinishProgram({ setFormData, formData, changeStep }) {
  const name = formData.name;
  const [showDiv, setShowDiv] = useState(false);
  const [spanWeightStyle, setSpanWeightStyle] = useState({});
  const spanStyle = {
    textTransform: "capitalize",
    fontWeight: "bold",
    letterSpacing: ".7px",
    fontSize: "15px",
    marginLeft: "10px",
  };

  useEffect(() => {
    setTimeout(() => {
      setShowDiv(true);
    }, 3000);
  });
  function handelNextStep() {
    changeStep(1);
  }
  return (
    <div className="changeDiv">
      {!showDiv ? (
        <h3 className="createProgram creatingBMI">
          finishing your program <i className="fa-solid fa-gear"></i>
        </h3>
      ) : (
        <div>
          <h2>Your Program is Ready {formData.name} !</h2>
          <p>
            <b>Your personalized program</b> is now ready! Remember, achieving
            your goals isn't just about exercise and diet— <b>rest</b> is
            equally important. Prioritize your sleep, recharge your body, and
            wake up each day <b>stronger</b> and more focused on your journey to
            success. <b>You've got this!</b>
          </p>
          <div className="buttons">
            <button className="backbutton" onClick={() => changeStep(-1)}>
              Back
            </button>
            <button className="nextbutton" onClick={() => handelNextStep()}>
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default FinishProgram;
