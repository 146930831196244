import React, { useEffect, useState } from "react";

function HowIsProgram({ setFormData, formData, changeStep }) {
  const name = formData.name;
  const [spanWeightStyle, setSpanWeightStyle] = useState({});
  const spanStyle = {
    textTransform: "capitalize",
    fontWeight: "bold",
    letterSpacing: ".7px",
    fontSize: "15px",
    marginLeft: "10px",
  };
  const handelNextStep = () => {
    changeStep(1);
  };
  const weekNumber = () => {
    if (formData.weightCategory == "Obesity class 2") {
      return " 16 ";
    } else if (formData.weightCategory == "Obesity class 3") {
      return " 20 ";
    } else {
      return " 12 ";
    }
  };
  return (
    <div className="changeDiv">
      <div>
        <h2 style={{ marginTop: "30px", marginBottom: "14px" }}>
          Let's talk about your program {formData.name} !
        </h2>
        <p>
          <b>What Can I Get from This Program?</b>
        </p>
        <p>
          Since you are in <b>{formData.weightCategory}</b> and can commit to
          exercise each week for <b>{formData.timeHoursPerWeek}</b>, and you
          <b>
            {formData.MedicalConditionsImpact == "I have"
              ? " have "
              : " don't have "}
          </b>
          <b>medical conditions</b> or medications that might impact your weight
          loss journey, here’s what <b>our program offers you:</b>
        </p>
        <ul
          style={{
            textAlign: "start",
            margin: "15px auto",
            lineHeight: "1.8",
            fontWeight: "500",
            letterSpacing: ".3px",
            fontSize: "15px",
            listStyleType: "circle",
            listStylePosition: "inside",
          }}
        >
          <li>
            <b>
              <u>Personalized Dietary Guidance:</u>
            </b>
            We offer customized advice on the right foods to help you stay
            within your calorie limits.
          </li>
          <li>
            <b>
              <u>Customized Exercise Routine:</u>
            </b>
            Based on your BMI and your available time, we’ll create a workout
            plan that fits your lifestyle.
          </li>
          <li>
            <b>
              <u>Beginner to Advanced Training:</u>
            </b>
            Whether you’re new to exercise or have some experience, we provide
            step-by-step instructions and video lessons to guide you confidently
            through each exercise.
          </li>
          <li>
            <b>
              <u>Sleep and Stress Tips:</u>
            </b>
            We’ll offer practical tips to enhance your sleep hygiene and manage
            stress, supporting your overall health and progress.
          </li>
          <li>
            <b>
              <u>Program Duration:</u>
            </b>
            Our program lasts
            {weekNumber()}
            weeks, designed to deliver visible results and foster a healthier
            lifestyle. Join 653 others who are transforming their lives with our
            program!
          </li>
          <li>
            <b>
              <u>Expectations:</u>
            </b>
            See steady progress toward a healthier weight with a plan that fits
            your needs and schedule.
          </li>
        </ul>
        <div className="buttons">
          <button className="backbutton" onClick={() => changeStep(-1)}>
            Back
          </button>
          <button className="nextbutton" onClick={() => handelNextStep()}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default HowIsProgram;
