import axios from "axios";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import TokenContextProvider from "./context/TokenContextProvider";
const ProtectedRoute = ({ children }) => {
  const [isVerified, setIsVerified] = useState(null);
  let token = localStorage.getItem("token");
  useEffect(() => {
    if (!token) {
      setIsVerified(false);
    } else {
      const verifyToken = async () => {
        try {
          let res = await axios.post(
            `${process.env.REACT_APP_API_URL}auth/verifyToken`,
            {},
            {
              headers: {
                "Content-Type": "application/json",
                token: token,
              },
            }
          );
          if (res.status == 200) {
            setIsVerified(true);
          }
        } catch (error) {
          console.log("Token verification failed", error);
          setIsVerified(false);
        }
      };
      verifyToken();
    }
  }, [token]);

  if (isVerified === null) {
    return <div className="loading">loading ...</div>;
  }
  if (!isVerified) {
    return <Navigate to={"/login"} replace />;
  }
  return <TokenContextProvider>{children}</TokenContextProvider>;
};

export default ProtectedRoute;
