import React from "react";
import stripePng from "../../../Accessts/stripe.png";

export default function Stripe() {
  return (
    <button type="button" className="paymentButton">
      <img src={stripePng} alt="stripe" />
    </button>
  );
}
