import "./App.css";
import Home from "./pages/home/home";
import Getprogram from "./pages/getprogram/getprogram.jsx";
import "./Accessts/all.min.css";
import "./Accessts/normalize.css";
import { Routes, Route } from "react-router-dom";
import Succuss from "./pages/getprogram/otherPages/success.jsx";
import Failed from "./pages/getprogram/otherPages/failed.jsx";
import NotFound from "./pages/getprogram/otherPages/NotFound.jsx";
import Login from "./pages/Auth/login.jsx";
import Dashboard from "./pages/dashboard/dashboard.jsx";
import ProtectedRoute from "./pages/dashboard/protectedRoute.jsx";
import DashboardContent from "./pages/dashboard/dashnoardContent.jsx";
import Payers from "./pages/dashboard/payers/payers.jsx";
import OnePayer from "./pages/dashboard/payers/onePayer/onePayer.jsx";
import Emails from "./pages/dashboard/emails/emails.jsx";
import OneEmail from "./pages/dashboard/emails/oneEmail/oneEmail.jsx";

function App() {
  return (
    <>
      <Routes>
        <Route path={"/"} element={<Home />} />
        <Route path={"/getprogram"} element={<Getprogram />} />
        <Route path={"/success"} element={<Succuss />} />
        <Route path={"/failed"} element={<Failed />} />
        <Route path={"/login"} element={<Login />} />
        <Route
          path={"/dashboard"}
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        >
          <Route path="payers" element={<Payers />} />
          <Route path="payers/:id" element={<OnePayer />} />
          <Route path="emails" element={<Emails />} />
          <Route path="emails/:id" element={<OneEmail />} />
          <Route path="" element={<DashboardContent />} />
        </Route>
        <Route path={"*"} element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
