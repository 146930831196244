import "./loading.css";

export default function loading() {
  return (
    <div className="loading">
      <div className="container">
        <div className="content">
          <i class="fa-solid fa-spinner"></i>
        </div>
      </div>
    </div>
  );
}
