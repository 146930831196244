import React, { useState } from "react";

function SleepHours({ selectLebel, setFormData, formData, changeStep }) {
  const [flag, setFlag] = useState(false);
  const [inputVal, setInputVal] = useState("");
  function handelNextStep() {
    setFlag(true);
    if (inputVal !== "") {
      setFormData({
        ...formData,
        sleepHours: inputVal,
      });
      changeStep(1);
    }
  }

  return (
    <div className="changeDiv">
      <h2>13. How many hours of sleep do you typically get each night?</h2>
      <p>(select one)</p>
      <>
        <input
          type="radio"
          id="sleepHours1"
          value="0-4 hours"
          onChange={(e) => setInputVal(e.target.value)}
        />
        <label
          htmlFor="sleepHours1"
          onClick={(e) => selectLebel(e.currentTarget)}
        >
          0-4 hours
        </label>
        <input
          type="radio"
          id="sleepHours2"
          value="5-6 hours"
          onChange={(e) => setInputVal(e.target.value)}
        />
        <label
          htmlFor="sleepHours2"
          onClick={(e) => selectLebel(e.currentTarget)}
        >
          5-6 hours
        </label>
        <input
          type="radio"
          id="sleepHours3"
          value="7-8 hours"
          onChange={(e) => setInputVal(e.target.value)}
        />
        <label
          htmlFor="sleepHours3"
          onClick={(e) => selectLebel(e.currentTarget)}
        >
          7-8 hours
        </label>
        <input
          type="radio"
          id="sleepHours4"
          value="9+ hours"
          onChange={(e) => setInputVal(e.target.value)}
        />
        <label
          htmlFor="sleepHours4"
          onClick={(e) => selectLebel(e.currentTarget)}
        >
          9+ hours
        </label>
      </>

      {!document.querySelector(".getprogram input:checked") && flag && (
        <p className="error">
          please select one to create a good program for you
        </p>
      )}
      <div className="buttons">
        <button className="backbutton" onClick={() => changeStep(-1)}>
          Back
        </button>
        <button className="nextbutton" onClick={() => handelNextStep()}>
          NEXT
        </button>
      </div>
    </div>
  );
}

export default SleepHours;
