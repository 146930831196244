import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./otherPages.css";
import { formDataContext } from "../context/formDataContext";

export default function NotFound() {
  const formDataContextNow = useContext(formDataContext);
  const [name, setName] = useState("");
  let savedFormData;

  const getFormDataFromLocalStorage = () => {
    if (localStorage.getItem("formData")) {
      savedFormData = JSON.parse(localStorage.getItem("formData"));
      formDataContextNow.setFormData(savedFormData);
      setName(savedFormData.name);
    } else {
      savedFormData = {};
    }
  };

  useEffect(() => {
    getFormDataFromLocalStorage();
  }, [savedFormData]);
  return (
    <div className="getprogram endPoint">
      <div className="container">
        <div className="icon" style={{ backgroundColor: "var(--main_color)" }}>
          <i>404!</i>
          <p>Sorry, the page not found</p>
        </div>
        <div className="bottom">
          <p>
            The link you followed probably broken or the page has been removed .
          </p>
          <button
            type="button"
            style={{
              backgroundColor: "var(--main_color)",
              borderColor: "var(--main_color)",
            }}
          >
            <Link to="/">go back</Link>
          </button>
        </div>
      </div>
    </div>
  );
}
