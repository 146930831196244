import React, { useState, useEffect } from "react";

function Age({ setFormData, formData, changeStep }) {
  const [flag, setFlag] = useState(false);
  function handelNextStep() {
    setFlag(true);
    if (!(document.querySelector(".getprogram input").value < 6)) {
      setFormData({
        ...formData,
        age: document.querySelector(".getprogram input").value + " years",
      });
      changeStep(1);
    }
  }
  useEffect(() => {
    document.querySelector("input").focus();
  });

  return (
    <div className="changeDiv">
      <h2>3. what is you Age {formData.name}?</h2>
      <p>
        we use it to calculate your <b>BMI</b>.
      </p>
      <>
        <input type="number" name="Age" placeholder="Your Age" />
      </>
      {document.querySelector(".getprogram input").value < 7 && flag && (
        <p className="error">please enter a valide Age</p>
      )}
      <div className="buttons">
        <button className="backbutton" onClick={() => changeStep(-1)}>
          back
        </button>
        <button className="nextbutton" onClick={() => handelNextStep()}>
          NEXT
        </button>
      </div>
    </div>
  );
}

export default Age;
