import React, { useState } from "react";

function Gender({ selectLebel, setFormData, formData, changeStep }) {
  const [flag, setFlag] = useState(false);
  function handelNextStep() {
    setFlag(true);
    if (document.querySelector(".getprogram input:checked")) {
      setFormData({
        ...formData,
        gender: document.querySelector(".getprogram input:checked").value,
      });
      changeStep(1);
    }
  }

  return (
    <div className="changeDiv">
      <h2>1. What is your gender?</h2>
      <p>( select one )</p>
      <>
        <input type="radio" name="gender" id="male" value="male" />
        <label htmlFor="male" onClick={(e) => selectLebel(e.target)}>
          male
        </label>
        <input type="radio" name="gender" value="female" id="female" />
        <label htmlFor="female" onClick={(e) => selectLebel(e.target)}>
          female
        </label>
      </>
      {!document.querySelector(".getprogram input:checked") && flag && (
        <p className="error">
          please select one to create a good program for you
        </p>
      )}
      <div className="buttons">
        <button className="nextbutton" onClick={() => handelNextStep()}>
          NEXT
        </button>
      </div>
    </div>
  );
}

export default Gender;
