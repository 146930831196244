import React from "react";
import paypalSvg from "../../../Accessts/paypal.svg";
import axios from "axios";
import "./payment.css";

function Paypal({ formData, setLoading, setGoToPayError }) {
  const handlePayment = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}payment`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res && res.data) {
        window.location.href = res.data.links[1].href;
      }
    } catch (error) {
      console.error("Error:", error);
      setGoToPayError(true);
      setLoading(false);
    }
  };
  return (
    <button
      type="button"
      className="paymentButton"
      onClick={(e) => handlePayment(e)}
    >
      <img src={paypalSvg} alt="paypal" />
    </button>
  );
}

export default Paypal;
