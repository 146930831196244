import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./payers.css";
import DashboardHeader from "../dashboardComponents/dashboardHeader";
import TimeAgo from "../dashboardComponents/timeAgo";
import Sendprogram from "../dashboardComponents/sendprogram";

export default function Payers() {
  const [allPayers, setAllPayers] = useState([]);
  const [filterMode, setFilterMode] = useState("all");
  const [allPayersCount, setAllPayersCount] = useState(0);
  const [sendedCount, setSendedCout] = useState(0);
  const [notSendedCount, setNotSendedCount] = useState(0);
  const [lastDayCount, setLastDayCount] = useState();
  const [lastWeekCount, setLastWeekCount] = useState();
  const [lastMonthCount, setLastMonthCount] = useState();

  const handleGetAllPayers = async () => {
    try {
      if (!localStorage.token) throw new Error("not token provider");
      const token = localStorage.token;
      let filterQuery = "";
      if (filterMode === "last-day") filterQuery = "?lastDay=true";
      else if (filterMode === "last-week") filterQuery = "?lastWeek=true";
      else if (filterMode === "last-month") filterQuery = "?lastMonth=true";
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}payers/getallpayers${filterQuery}`,
        {
          headers: { token: token },
        }
      );
      const data = await res.json();

      if (!data.AllPayers) return;

      setAllPayers(() => {
        if (filterMode === "all") {
          setAllPayersCount(data.AllPayers.length);
          setSendedCout(
            data.AllPayers.filter(
              (onePayer) => onePayer.theProgramSend === true
            ).length
          );
          setNotSendedCount(
            data.AllPayers.filter(
              (onePayer) => onePayer.theProgramSend === false
            ).length
          );
          return data.AllPayers;
        } else if (filterMode === "sended") {
          return data.AllPayers.filter(
            (onePayer) => onePayer.theProgramSend === true
          );
        } else if (filterMode === "not-sended") {
          return data.AllPayers.filter(
            (onePayer) => onePayer.theProgramSend === false
          );
        } else if (filterMode === "last-day") {
          setLastDayCount(data.AllPayers.length);
          return data.AllPayers;
        } else if (filterMode === "last-week") {
          setLastWeekCount(data.AllPayers.length);
          return data.AllPayers;
        } else {
          setLastMonthCount(data.AllPayers.length);
          return data.AllPayers;
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGetAllPayers();
  }, [filterMode]);

  const handleFilterChange = (filterValue, element) => {
    setFilterMode(filterValue);
    Array.from(element.parentElement.children).forEach((child) => {
      child.className = "";
    });
    element.className = "active";
  };

  return (
    <div className="content content-list">
      <DashboardHeader title={"Payers"} />
      <main>
        <ol className="filter">
          <li
            className="active"
            onClick={(e) => handleFilterChange("all", e.target)}
          >
            All({allPayersCount})
          </li>
          <li onClick={(e) => handleFilterChange("sended", e.target)}>
            Sended({sendedCount})
          </li>
          <li onClick={(e) => handleFilterChange("not-sended", e.target)}>
            Not-Sended({notSendedCount})
          </li>
          <li onClick={(e) => handleFilterChange("last-day", e.target)}>
            Last day({lastDayCount})
          </li>
          <li onClick={(e) => handleFilterChange("last-week", e.target)}>
            Last week ({lastWeekCount})
          </li>
          <li onClick={(e) => handleFilterChange("last-month", e.target)}>
            Last month ({lastMonthCount})
          </li>
        </ol>
        <div className="allList">
          <ol>
            {allPayers.length === 0 ? (
              <li>No payers available</li>
            ) : (
              allPayers.map((payer, index) => (
                <li
                  key={index}
                  style={
                    !payer.theProgramSend
                      ? {
                          border: "1px solid #f18a8461",
                          backgroundColor: "#00ff2b05",
                        }
                      : null
                  }
                >
                  <div
                    className="foto"
                    style={{
                      backgroundColor:
                        payer.custom.gender === "male" ? "#849be9" : "#f18a84",
                    }}
                  >
                    {payer.custom.gender === "male" ? (
                      <i className="fa-solid fa-person"></i>
                    ) : (
                      <i className="fa-solid fa-person-dress"></i>
                    )}
                  </div>
                  <div>
                    <div className="info-1">
                      <h4 className="name">{payer.custom.name}</h4>
                      <Sendprogram
                        index={index}
                        payerId={payer._id}
                        payerTheProgramSend={payer.theProgramSend}
                        handleGetAllPayers={handleGetAllPayers}
                      />
                    </div>
                    <div className="info-2">
                      <div className="email">{payer.custom.email}</div>
                      <div className="date">
                        <TimeAgo dateString={payer.custom.createdAt} />
                      </div>
                      <Link to={`${payer._id}`}>More Info</Link>
                    </div>
                  </div>
                </li>
              ))
            )}
          </ol>
        </div>
      </main>
    </div>
  );
}
