import React, { useEffect, useState } from "react";
import DashboardHeader from "../dashboardComponents/dashboardHeader";
import { Link } from "react-router-dom";
import TimeAgo from "../dashboardComponents/timeAgo";
import "./emails.css";

export default function Emails() {
  const [allEmails, setAllEmails] = useState([
    {
      _id: "66e2e0fe09ee0d06904356ed",
      gender: "male",
      name: "ahmed",
      age: "837 years",
      weight: "2323 kg",
      tall: "2323 cm",
      weightCategory: "Underweight",
      bmi: 4.3,
      activityLevel: "Active",
      timeHoursPerWeek: "6+ hours",
      triedAnyPrpgramBefore: "no",
      email: "yassinazarguy16@gmail.com",
      isPayer: true,
      createdAt: "2024-09-12T12:39:26.412Z",
      updatedAt: "2024-09-12T12:39:26.412Z",
      __v: 0,
    },
    {
      _id: "66e2e0fe09ee0d06904356ed",
      gender: "male",
      name: "ahmed",
      age: "837 years",
      weight: "2323 kg",
      tall: "2323 cm",
      weightCategory: "Underweight",
      bmi: 4.3,
      activityLevel: "Active",
      timeHoursPerWeek: "6+ hours",
      triedAnyPrpgramBefore: "no",
      email: "yassinazarguy16@gmail.com",
      createdAt: "2024-09-12T12:39:26.412Z",
      updatedAt: "2024-09-12T12:39:26.412Z",
      __v: 0,
    },
  ]);
  const [filterMode, setFilterMode] = useState("all");
  const [allEmailsCount, setAllEmailsCount] = useState(0);
  const [payersEmailCount, setPayersEmailCout] = useState(0);
  const [notPayersEmailCount, setNotPayersEmailCount] = useState(0);
  const [lastDayCount, setLastDayCount] = useState();
  const [lastWeekCount, setLastWeekCount] = useState();
  const [lastMonthCount, setLastMonthCount] = useState();

  const handleGetAllPayers = async () => {
    try {
      if (!localStorage.token) throw new Error("not token provider");
      const token = localStorage.token;
      let filterQuery = "";
      if (filterMode === "last-day") filterQuery = "?lastDay=true";
      else if (filterMode === "last-week") filterQuery = "?lastWeek=true";
      else if (filterMode === "last-month") filterQuery = "?lastMonth=true";
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}nembers${filterQuery}`,
        {
          headers: { token: token },
        }
      );
      const data = await res.json();

      if (!data) return;

      setAllEmails(() => {
        if (filterMode === "all") {
          setAllEmailsCount(data.length);
          setPayersEmailCout(
            data.filter((oneEmail) => oneEmail.isPayer === true).length
          );
          setNotPayersEmailCount(
            data.filter((oneEmail) => oneEmail.isPayer === false).length
          );
          return data;
        } else if (filterMode === "payers") {
          return data.filter((oneEmail) => oneEmail.isPayer === true);
        } else if (filterMode === "not-payers") {
          return data.filter((oneEmail) => oneEmail.isPayer === false);
        } else if (filterMode === "last-day") {
          setLastDayCount(data.length);
          return data;
        } else if (filterMode === "last-week") {
          setLastWeekCount(data.length);
          return data;
        } else {
          setLastMonthCount(data.length);
          return data;
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGetAllPayers();
  }, [filterMode]);

  const handleFilterChange = (filterValue, element) => {
    setFilterMode(filterValue);
    Array.from(element.parentElement.children).forEach((child) => {
      child.className = "";
    });
    element.className = "active";
  };

  return (
    <div className="content content-list">
      <DashboardHeader title={"Emails"} />
      <main>
        <ol className="filter">
          <li
            className="active"
            onClick={(e) => handleFilterChange("all", e.target)}
          >
            All({allEmailsCount})
          </li>
          <li onClick={(e) => handleFilterChange("payers", e.target)}>
            Payers({payersEmailCount})
          </li>
          <li onClick={(e) => handleFilterChange("not-payers", e.target)}>
            Not-Payers({notPayersEmailCount})
          </li>
          <li onClick={(e) => handleFilterChange("last-day", e.target)}>
            Last day({lastDayCount})
          </li>
          <li onClick={(e) => handleFilterChange("last-week", e.target)}>
            Last week ({lastWeekCount})
          </li>
          <li onClick={(e) => handleFilterChange("last-month", e.target)}>
            Last month ({lastMonthCount})
          </li>
        </ol>
        <div className="allList">
          <ol>
            {allEmails.length === 0 ? (
              <li>No emails available</li>
            ) : (
              allEmails.map((email, index) => (
                <li
                  key={index}
                  style={
                    !email.isPayer
                      ? {
                          border: "1px solid #f18a8461",
                          backgroundColor: "#00ff2b05",
                        }
                      : null
                  }
                >
                  <div
                    className="foto"
                    style={{
                      backgroundColor:
                        email.gender === "male" ? "#849be9" : "#f18a84",
                    }}
                  >
                    {email.gender === "male" ? (
                      <i className="fa-solid fa-person"></i>
                    ) : (
                      <i className="fa-solid fa-person-dress"></i>
                    )}
                  </div>
                  <div>
                    <div className="info-1">
                      <h4 className="name">{email.name}</h4>
                      <div
                        className="isPayer"
                        style={{ color: email.isPayer ? "#63cb6a" : "#cb6363" }}
                      >
                        {email.isPayer ? "is payer" : "is not payer"}
                      </div>
                    </div>
                    <div className="info-2">
                      <div className="email">{email.email}</div>
                      <div className="date">
                        <TimeAgo dateString={email.createdAt} />
                      </div>
                      <Link to={`${email._id}`}>More Info</Link>
                    </div>
                  </div>
                </li>
              ))
            )}
          </ol>
        </div>
      </main>
    </div>
  );
}
