import React, { useContext, useEffect, useState } from "react";
import { NavLink, Link, Outlet } from "react-router-dom";
import "./dashboard.css";
import { TokenContext } from "./context/TokenContextProvider";
export default function Dashboard() {
  const tokenContext = useContext(TokenContext);
  if (localStorage.getItem("token")) {
    let token = localStorage.getItem("token");
    tokenContext.setToken(token);
  }

  return (
    <div className="dashboard">
      <div className="sideBar">
        <div className="logo">easySlim</div>
        <ol>
          <li>
            <NavLink
              to={"/dashboard"}
              activeclassname="active"
              className={"sideBarLink"}
            >
              <i className="fa-solid fa-house"></i>
              Dashboard
            </NavLink>
          </li>
          {/* <li>
            <NavLink
              to={"dashboard/revenue"}
              activeclassname="active"
              className={"sideBarLink"}
            >
              <i className="fa-solid fa-dollar-sign"></i>
              Revenue
            </NavLink>
          </li> */}
          <li>
            <NavLink
              to={"payers"}
              activeclassname="active"
              className={"sideBarLink"}
            >
              <i className="fa-solid fa-user"></i>
              Payers
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"emails"}
              activeclassname="active"
              className={"sideBarLink"}
            >
              <i className="fa-solid fa-at"></i>
              Emails
            </NavLink>
          </li>
          {/* <li>
            <NavLink
              to={"dashboard/messages"}
              activeclassname="active"
              className={"sideBarLink"}
            >
              <i className="fa-solid fa-message"></i>
              messages
            </NavLink>
          </li> */}
        </ol>
      </div>
      <Outlet />
    </div>
  );
}
