import React, { useState } from "react";

function TimeHours({ selectLebel, setFormData, formData, changeStep }) {
  const [flag, setFlag] = useState(false);
  const [inputVal, setInputVal] = useState("");
  function handelNextStep() {
    setFlag(true);
    if (inputVal !== "") {
      setFormData({
        ...formData,
        timeHoursPerWeek: inputVal,
      });
      changeStep(1);
    }
  }

  return (
    <div className="changeDiv">
      <h2>10. How much time can you commit to exercise each week?</h2>
      <p>
        We’re happy you’re here <b>{formData.name}</b>.
      </p>
      <>
        <input
          type="radio"
          name="timeHours"
          id="timeHours1"
          value="0-1 hour"
          onChange={(e) => setInputVal(e.target.value)}
        />
        <label
          htmlFor="timeHours1"
          onClick={(e) => selectLebel(e.currentTarget)}
        >
          0-1 hour
        </label>
        <input
          type="radio"
          name="timeHours"
          id="timeHours2"
          value="2-3 hours"
          onChange={(e) => setInputVal(e.target.value)}
        />
        <label
          htmlFor="timeHours2"
          onClick={(e) => selectLebel(e.currentTarget)}
        >
          2-3 hours
        </label>
        <input
          type="radio"
          name="timeHours"
          id="timeHours3"
          value="4-5 hours"
          onChange={(e) => setInputVal(e.target.value)}
        />
        <label
          htmlFor="timeHours3"
          onClick={(e) => selectLebel(e.currentTarget)}
        >
          4-5 hours
        </label>
        <input
          type="radio"
          name="timeHours"
          id="timeHours4"
          value="6+ hours"
          onChange={(e) => setInputVal(e.target.value)}
        />
        <label
          htmlFor="timeHours4"
          onClick={(e) => selectLebel(e.currentTarget)}
        >
          6+ hours
        </label>
      </>

      {!document.querySelector(".getprogram input:checked") && flag && (
        <p className="error">
          please select one to create a good program for you
        </p>
      )}
      <div className="buttons">
        <button className="backbutton" onClick={() => changeStep(-1)}>
          Back
        </button>
        <button className="nextbutton" onClick={() => handelNextStep()}>
          NEXT
        </button>
      </div>
    </div>
  );
}

export default TimeHours;
