import React, { useEffect, useState } from "react";
import DashboardHeader from "../../dashboardComponents/dashboardHeader";
import OnePayerCart from "../../payers/onePayer/onePayerCart";
import TimeAgo from "../../dashboardComponents/timeAgo";

export default function OneEmail() {
  const [EmailInfo, setEmailInfo] = useState();
  const userId = window.location.pathname.split("/").slice(-1)[0];
  console.log(userId);
  useEffect(() => {
    async function fetchEmailInfo() {
      try {
        if (!userId) {
          throw new Error("there is not an Id");
        }
        if (!localStorage.token || localStorage.token === "") {
          throw new Error("there is not token");
        }
        const token = localStorage.token;

        const res = await fetch(`${process.env.REACT_APP_API_URL}nembers/${userId}`, {
          headers: {
            token: token,
          },
        });
        const data = await res.json();
        setEmailInfo(data.email);
      } catch (error) {
        console.error(error);
      }
    }
    fetchEmailInfo();
  }, [1]);

  return (
    <div className="content">
      <DashboardHeader title={"Detais"} />
      {!EmailInfo ? (
        <p>Something wrong !</p>
      ) : (
        <main className="showInformation">
          <div className="top">
            <h1>{EmailInfo.name.split(" ")[0]}</h1>
            <h4>{<TimeAgo dateString={EmailInfo.createdAt} />}</h4>
          </div>
          <OnePayerCart
            title={{
              content: "informations",
              iClass: "fa-solid fa-user",
            }}
            theList={[
              { content: "name", value: EmailInfo.name },
              { content: "email", value: EmailInfo.email },
              { content: "gender", value: EmailInfo.gender },
              {
                content: "is payer",
                value: EmailInfo.theProgramSend ? "yes" : "no",
              },
              {
                content: "age",
                value: EmailInfo.age,
              },
              {
                content: "weight",
                value: EmailInfo.weight,
              },
              {
                content: "tall",
                value: EmailInfo.tall,
              },
              { content: "wight category", value: EmailInfo.weightCategory },
              { content: "BMI", value: EmailInfo.bmi },
              { content: "activity Level", value: EmailInfo.activityLevel },
              {
                content: "time Hours PerWeek",
                value: EmailInfo.timeHoursPerWeek,
              },
              {
                content: "tried Any Prpgram Before",
                value: EmailInfo.triedAnyPrpgramBefore,
              },
            ]}
          />
        </main>
      )}
    </div>
  );
}
