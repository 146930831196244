import React from "react";
import { Link } from "react-router-dom";

function footer() {
  return (
    <>
      <footer>
        <div className="container">
          <h1>
            <Link to={"/"}>EasySlim</Link>
          </h1>
        </div>
      </footer>
      <div className="topfooter">
        <div className="container" dir="rtl">
          <span>
            Copyright © 2024 <Link to={"/"}>EasySlim</Link> , All Rights
            Reserved.
          </span>
          <span>
            - Disagned by <Link>yassine</Link>© -
          </span>
        </div>
      </div>
    </>
  );
}

export default footer;
