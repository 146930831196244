import React from "react";
import axios from "axios";

export default function Sendprogram({
  index,
  payerId,
  payerTheProgramSend,
  handleGetAllPayers,
}) {
  const handleChangeSendTheProgram = async (
    _id = payerId,
    theProgramSend = payerTheProgramSend
  ) => {
    try {
      if (!localStorage.token) throw new Error("not token provider");
      const token = localStorage.token;
      await axios.post(
        `${process.env.REACT_APP_API_URL}payers/changesendprogram`,
        { _id, programIsSend: !theProgramSend },
        { headers: { "Content-Type": "application/json", token: token } }
      );
      handleGetAllPayers();
    } catch (error) {
      console.error({ error: error.message });
    }
  };
  return (
    <div
      className="sendTheProgram"
      style={{
        backgroundColor: payerTheProgramSend ? "#63cb6a" : "#cb6363",
      }}
    >
      <label htmlFor={`sendProgram-${index + 1}`}>The program sent</label>
      <input
        id={`sendProgram-${index + 1}`}
        type="checkbox"
        checked={payerTheProgramSend}
        onChange={() =>
          handleChangeSendTheProgram(payerId, payerTheProgramSend)
        }
      />
    </div>
  );
}
