import React, { useState } from "react";

function MedicalConditions({ selectLebel, setFormData, formData, changeStep }) {
  const [flag, setFlag] = useState(false);
  const [inputVal, setInputVal] = useState("");
  function handelNextStep() {
    setFlag(true);
    if (inputVal !== "") {
      setFormData({
        ...formData,
        MedicalConditionsImpact: inputVal,
      });
      changeStep(1);
    }
  }

  return (
    <div className="changeDiv">
      <h2>
        12. Are there any medical conditions or medications that might impact
        your weight loss journey?
      </h2>
      <>
        <input
          type="radio"
          name="medicalCondition"
          id="medicalCondition1"
          value="I have"
          onChange={(e) => setInputVal(e.target.value)}
        />
        <label
          htmlFor="medicalCondition1"
          onClick={(e) => selectLebel(e.target)}
        >
          I have
        </label>
        <input
          type="radio"
          name="medicalCondition"
          id="medicalCondition2"
          value="I don't have"
          onChange={(e) => setInputVal(e.target.value)}
        />
        <label
          htmlFor="medicalCondition2"
          onClick={(e) => selectLebel(e.target)}
        >
          I don't have
        </label>
      </>

      {!document.querySelector(".getprogram input:checked") && flag && (
        <p className="error">
          please select one to create a good program for you
        </p>
      )}
      <div className="buttons">
        <button className="backbutton" onClick={() => changeStep(-1)}>
          Back
        </button>
        <button className="nextbutton" onClick={() => handelNextStep()}>
          NEXT
        </button>
      </div>
    </div>
  );
}

export default MedicalConditions;
