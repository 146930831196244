import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./otherPages.css";
import { formDataContext } from "../context/formDataContext";

export default function Failed() {
  const formDataContextNow = useContext(formDataContext);
  const [name, setName] = useState("");
  let savedFormData;

  const getFormDataFromLocalStorage = () => {
    if (localStorage.getItem("formData")) {
      savedFormData = JSON.parse(localStorage.getItem("formData"));
      formDataContextNow.setFormData(savedFormData);
      setName(savedFormData.name);
    } else {
      savedFormData = {};
    }
  };

  useEffect(() => {
    getFormDataFromLocalStorage();
  }, [savedFormData]);
  return (
    <div className="getprogram endPoint">
      <div className="container">
        <div className="icon" style={{ backgroundColor: "red" }}>
          <i className="fa-solid fa-circle-exclamation"></i>
          <p>failed</p>
        </div>
        <div className="bottom">
          <p>
            If you're having trouble with payment <b>{name}</b>, please send us
            a message at 'yassinazarguy12@gmail.com'. We'll respond promptly to
            assist you.
          </p>
          <button
            type="button"
            style={{ backgroundColor: "red", borderColor: "red" }}
          >
            <Link to="/getprogram">go back</Link>
          </button>
        </div>
      </div>
    </div>
  );
}
