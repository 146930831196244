import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./otherPages.css";
import axios from "axios";

export default function Succuss() {
  const [callSupport, setCallSupport] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  let savedFormData;

  const getFormDataFromLocalStorage = () => {
    if (localStorage.getItem("formData")) {
      savedFormData = JSON.parse(localStorage.getItem("formData"));
      setName(savedFormData.name);
      setEmail(savedFormData.email);
    } else {
      savedFormData = {};
    }
  };
  const sendFormData = async () => {
    try {
      let res = await axios.post(
        `${process.env.REACT_APP_API_URL}payers`,
        { _id: savedFormData._id },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res.status === 201) {
        setCallSupport(false);
      } else {
        setCallSupport(true);
      }
    } catch (error) {
      if (error.response && error.response.status) {
        setCallSupport(true);
      }
      console.error(
        "There is a problem:",
        error.response ? error.response.data : error.message
      );
    }
  };
  useEffect(() => {
    getFormDataFromLocalStorage();
    sendFormData();
  }, [savedFormData]);
  return (
    <div className="getprogram endPoint">
      <div className="container">
        <div className="icon">
          <i className="fa-regular fa-circle-check"></i>
          <p>success</p>
        </div>
        <div className="bottom">
          <p>
            Thank you <b>{name}</b> for your trust and commitment to improving
            your health. We're thrilled to have you on board!
          </p>
          <p>
            Your personalized program is now in the works and will be crafted
            with care to meet your specific needs. Please allow us <b>7 days</b>{" "}
            to create a plan tailored just for you. Once it's ready, we'll send
            it straight to your email "<b>{email}</b>".
          </p>
          {callSupport && (
            <p>
              Please send us a message at <b>'yassinazarguy12@gmail.com'</b> as
              there was an issue with your email delivery.
            </p>
          )}
          <button type="button">
            <Link to="/">go back</Link>
          </button>
        </div>
      </div>
    </div>
  );
}
