import React, { useState, useEffect } from "react";

function Name({ setFormData, formData, changeStep }) {
  const [flag, setFlag] = useState(false);
  function handelNextStep() {
    setFlag(true);
    if (document.querySelector(".getprogram input").value.length > 2) {
      setFormData({
        ...formData,
        name: document.querySelector(".getprogram input").value,
      });
      changeStep(1);
    }
  }

  useEffect(() => {
    document.querySelector("input").focus();
  });
  return (
    <div className="changeDiv">
      <h2>2. What is your name?</h2>
      <p>We’re happy you’re here.</p>
      <p>
        Let’s calculat your body mass index <b>(BMI)</b>.
      </p>
      <>
        <input type="text" name="name" placeholder="First Name" />
      </>
      {!(document.querySelector(".getprogram input").value.length > 3) &&
        flag && <p className="error">please enter a valide name</p>}
      <div className="buttons">
        <button className="backbutton" onClick={() => changeStep(-1)}>
          back
        </button>
        <button className="nextbutton" onClick={() => handelNextStep()}>
          NEXT
        </button>
      </div>
    </div>
  );
}

export default Name;
