import React, { useEffect, useRef, useState } from "react";

function Weight({ setFormData, formData, changeStep, selectLebel }) {
  const [flag, setFlag] = useState(false);
  const inputRef = useRef(null);
  const [unitInput, setUnitInput] = useState("kg");
  function handelNextStep() {
    const inputValue = inputRef.current?.value;
    setFlag(true);
    if (inputValue > 0) {
      const weight = inputValue + " " + unitInput;
      setFormData({
        ...formData,
        weight: weight,
      });
      changeStep(1);
    }
  }
  useEffect(() => {
    document.querySelector("input").focus();
  });

  return (
    <div className="changeDiv">
      <h2>4. What is your current weight?</h2>
      <p>
        We’re happy you’re here <b>{formData.name}</b>.
      </p>
      <p>
        we use it to calculate your <b>BMI</b>.
      </p>
      <>
        <input
          type="number"
          name="Weight"
          placeholder="Weight"
          className="smallInput"
          ref={inputRef}
        />
        <input
          type="radio"
          name="tallType"
          id="kg"
          value="kg"
          checked={true}
          onChange={() => setUnitInput("kg")}
        />
        <label
          htmlFor="kg"
          className="smallLabel active"
          onClick={(e) => selectLebel(e.target)}
        >
          kg
        </label>
        <input
          type="radio"
          name="tallType"
          id="lbs"
          value="lbs"
          onChange={() => setUnitInput("lbs")}
        />
        <label
          htmlFor="lbs"
          className="smallLabel"
          onClick={(e) => selectLebel(e.target)}
        >
          lbs
        </label>
      </>
      {!(inputRef.current?.value > 0) && flag && (
        <p className="error">please enter a valide Weight</p>
      )}
      <div className="buttons">
        <button className="backbutton" onClick={() => changeStep(-1)}>
          back
        </button>
        <button className="nextbutton" onClick={() => handelNextStep()}>
          NEXT
        </button>
      </div>
    </div>
  );
}

export default Weight;
