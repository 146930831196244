import React, { useEffect, useState } from "react";
import DashboardHeader from "../../dashboardComponents/dashboardHeader";
import OnePayerCart from "./onePayerCart";
import TimeAgo from "../../dashboardComponents/timeAgo";

export default function OnePayer() {
  const [PayerInfo, setPayerInfo] = useState([]);
  useEffect(async () => {
    // const thePayer=await fetch('',)
    // setPayerInfo(thePayer)
  });

  return (
    <div className="content">
      <DashboardHeader title={"Detais"} />
      {PayerInfo.length === 0 ? (
        <p>Something wrong !</p>
      ) : (
        <main className="showInformation">
          <div className="top">
            <h1>{PayerInfo.custom.name.split(" ")[0]}</h1>
            <h4>{<TimeAgo dateString={PayerInfo.update_time} />}</h4>
          </div>
          <OnePayerCart
            title={{
              content: "generall information",
              iClass: "fa-solid fa-user",
            }}
            theList={[
              { content: "name", value: PayerInfo.custom.name },
              { content: "email", value: PayerInfo.custom.email },
              {
                content: "program",
                value: PayerInfo.theProgramSend ? "sended" : "not sended",
              },
              {
                content: "payement methode",
                value: PayerInfo.payer.payment_method,
              },
              {
                content: "payer name",
                value:
                  PayerInfo.payer.payer_info.first_name +
                  " " +
                  PayerInfo.payer.payer_info.last_name,
              },
              {
                content: "payer email",
                value: PayerInfo.payer.payer_info.email,
              },
              { content: "transaction Id", value: PayerInfo.transactionId },
              { content: "totale", value: PayerInfo.amount.total + "$" },
            ]}
          />
          <OnePayerCart
            title={{
              content: "personal information",
              iClass: "fa-solid fa-dumbbell",
            }}
            theList={[
              { content: "name", value: PayerInfo.custom.name },
              { content: "gender", value: PayerInfo.custom.gender },
              { content: "email", value: PayerInfo.custom.email },
              { content: "tall", value: PayerInfo.custom.tall },
              { content: "weight", value: PayerInfo.custom.weight },
              {
                content: "weight category",
                value: PayerInfo.custom.weightCategory,
              },
              { content: "BMI", value: PayerInfo.custom.bmi },
              {
                content: "activity level",
                value: PayerInfo.custom.activityLevel,
              },
              {
                content: "time hours per week",
                value: PayerInfo.custom.timeHoursPerWeek,
              },
              {
                content: "tried any prpgram before",
                value: PayerInfo.custom.triedAnyPrpgramBefore,
              },
            ]}
          />
          <OnePayerCart
            title={{
              content: "payement information",
              iClass: "fa-regular fa-credit-card",
            }}
            theList={[
              { content: "name", value: PayerInfo.custom.name },
              { content: "transaction Id", value: PayerInfo.transactionId },
              { content: "cart", value: PayerInfo.cart },
              {
                content: "payment method",
                value: PayerInfo.payer.payment_method,
              },
              { content: "status", value: PayerInfo.payer.status },
              {
                content: "recipient name",
                value:
                  PayerInfo.payer.payer_info.shipping_address.recipient_name,
              },
              {
                content: "line1",
                value: PayerInfo.payer.payer_info.shipping_address.line1,
              },
              {
                content: "city",
                value: PayerInfo.payer.payer_info.shipping_address.city,
              },
              {
                content: "state",
                value: PayerInfo.payer.payer_info.shipping_address.state,
              },
              {
                content: "postal_code",
                value: PayerInfo.payer.payer_info.shipping_address.postal_code,
              },
              {
                content: "country_code",
                value: PayerInfo.payer.payer_info.shipping_address.country_code,
              },
              {
                content: "payer email",
                value: PayerInfo.payer.payer_info.email,
              },
              {
                content: "payer name",
                value:
                  PayerInfo.payer.payer_info.first_name +
                  " " +
                  PayerInfo.payer.payer_info.last_name,
              },
              {
                content: "payerid",
                value: PayerInfo.payer.payer_info.payer_id,
              },
              {
                content: "business_name",
                value: PayerInfo.payer.payer_info.business_name,
              },
              {
                content: "total",
                value: PayerInfo.amount.total + "$",
              },
            ]}
          />
        </main>
      )}
    </div>
  );
}
