import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { TokenContext } from "./context/TokenContextProvider";
import DashboardHeader from "./dashboardComponents/dashboardHeader";

export default function DashboardContent() {
  const [payersCount, setPayersCount] = useState(0);
  const [emailsCount, setEmailsCount] = useState(0);
  console.log(payersCount, emailsCount);
  const tokenContext = useContext(TokenContext);
  const token = tokenContext.token;
  const tokentVerify = () => {
    if (!token || token === "") {
      return false;
    } else {
      return true;
    }
  };
  useEffect(() => {
    const getEmailCount = async () => {
      if (tokentVerify()) {
        try {
          let res = await axios.get(`${process.env.REACT_APP_API_URL}nembers/count`, {
            headers: {
              token: token,
            },
          });
          setEmailsCount(res.data.emailsCount);
        } catch (error) {
          console.error(error);
        }
      }
    };
    const getPayerCount = async () => {
      if (tokentVerify()) {
        try {
          let res = await axios.get(`${process.env.REACT_APP_API_URL}payers/count`, {
            headers: {
              token: token,
            },
          });
          setPayersCount(res.data.payersCount);
        } catch (error) {
          console.error(error);
        }
      }
    };
    getPayerCount();
    getEmailCount();
  }, [token]);
  return (
    <div className="content">
      <DashboardHeader title={"Dashboard"} />

      <main>
        <div className="topButtons">
          {
            //<div className="oneButton" style={{ backgroundColor: "var(--main_color)" }} > <div className="top"> <i class="fa-solid fa-eye"></i>Visitors </div> <h1>274</h1> <p> +<b>28 views</b> than last day </p> </div>
          }
          <div className="oneButton" style={{ backgroundColor: "#f56866" }}>
            <div className="top">
              <i class="fa-solid fa-user"></i>Payers
            </div>
            <h1>{payersCount}</h1>
            <p>
              +<b>28 payers</b> than last day
            </p>
          </div>
          <div className="oneButton" style={{ backgroundColor: "#12bc95" }}>
            <div className="top">
              <i class="fa-solid fa-at"></i>Emails
            </div>
            <h1>{emailsCount}</h1>
            <p>
              +<b>15 emails</b> than last day
            </p>
          </div>
        </div>
        {
          //<Graph />
        }
      </main>
    </div>
  );
}
