import React from "react";
import { Link } from "react-router-dom";

export default function DashboardHeader({ title }) {
  return (
    <div className="dashHeader">
      <h2 className="logo">{title}</h2>
      <div className="icons">
        <div className="notification">
          <Link>
            <i className="fa-regular fa-bell"></i>
          </Link>
        </div>
        <div className="message">
          <Link>
            <i className="fa-regular fa-comment"></i>
          </Link>
        </div>
      </div>
    </div>
  );
}
