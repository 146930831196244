import React, { useEffect, useState } from "react";
import axios from "axios";
function GetEmail({ setFormData, formData, changeStep }) {
  const [flag, setFlag] = useState(false);
  const [inputVal, setInputVal] = useState("");
  const [emailError, setEmailError] = useState(false);

  const handleSubmit = async () => {
    const formDataToSend = {
      medicalConditionsImpact: formData.medicalConditionsImpact,
      activityLevel: formData.activityLevel,
      age: formData.age,
      bmi: formData.bmi,
      email: inputVal,
      gender: formData.gender,
      name: formData.name,
      sleepHours: formData.sleepHours,
      tall: formData.tall,
      timeHoursPerWeek: formData.timeHoursPerWeek,
      triedAnyPrpgramBefore: formData.triedAnyPrpgramBefore,
      weight: formData.weight,
      weightCategory: formData.weightCategory,
    };

    try {
      let res = await axios.post(
        `${process.env.REACT_APP_API_URL}nembers`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res.status === 201) {
        setEmailError(false);
        setFormData({
          ...formData,
          _id: res.data._id,
          email: inputVal,
        });
        changeStep(1);
      } else {
        setEmailError(true);
      }
    } catch (error) {
      if (error.response && error.response.status) {
        setEmailError(true);
      }
      console.error(
        "There is a problem:",
        error.response ? error.response.data : error.message
      );
    }
  };

  function handelNextStep(e) {
    setFlag(true);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Define emailRegex here

    if (emailRegex.test(inputVal)) {
      setFormData({
        ...formData,
        email: inputVal,
      });

      handleSubmit(e);
    }
  }

  useEffect(() => {
    document.querySelector("input").focus();
  });
  return (
    <div className="changeDiv">
      <h2>Enter your email below :</h2>
      <p>
        to receive <b>your program</b>
      </p>
      <>
        <input
          type="email"
          name="email"
          placeholder="Your Email"
          onChange={(e) => setInputVal(e.target.value)}
        />
      </>
      {!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputVal) && flag && (
        <p className="error">Please enter a valid email</p>
      )}
      {emailError && (
        <p className="error">
          this email is already submiting, try an other email .
        </p>
      )}
      <div className="buttons">
        <button className="backbutton" onClick={() => changeStep(-1)}>
          Back
        </button>
        <button className="nextbutton" onClick={(e) => handelNextStep(e)}>
          NEXT
        </button>
      </div>
    </div>
  );
}

export default GetEmail;
