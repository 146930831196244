import React, { useState } from "react";
import "./header.css";
import { Link } from "react-router-dom";

function Header() {
  const [showMenu, setShowMenu] = useState(false);
  let menu = document.getElementsByClassName("menu")[0];
  let lis = document.querySelectorAll(".menu ol li");

  function iconClick() {
    setShowMenu(true);
  }
  function closeClick() {
    setShowMenu(false);
  }
  lis.forEach((p) => {
    p.addEventListener("click", () => {
      menu.style.removeProperty("right");
    });
  });
  return (
    <header>
      <div className="container">
        <div className="content">
          <div className="logo">
            <h1>
              <Link to={"/"}>EasySlim</Link>
            </h1>
          </div>
          <div className="icon" onClick={() => iconClick()}>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div className="menu" style={{ right: showMenu ? "0" : "-100%" }}>
            <span id="close" onClick={() => closeClick()}>
              ×
            </span>
            <h1>Menu</h1>
            <hr />
            <ol>
              <li>
                <Link to={"/getprogram"}>Make your program</Link>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
