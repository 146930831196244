import React, { useState } from "react";

function ActivityLevel({ selectLebel, setFormData, formData, changeStep }) {
  const [flag, setFlag] = useState(false);
  const [inputVal, setInputVal] = useState("");
  function handelNextStep() {
    setFlag(true);
    if (inputVal !== "") {
      setFormData({
        ...formData,
        activityLevel: inputVal,
      });
      changeStep(1);
    }
  }

  return (
    <div className="changeDiv">
      <h2>9. What is your baseline activity level?</h2>
      <p>( Not including workouts–we count that separately .)</p>
      <>
        <input
          type="radio"
          name="activityLevel"
          id="activityLevel1"
          value="Not Very Active"
          onChange={(e) => setInputVal(e.target.value)}
        />
        <label
          htmlFor="activityLevel1"
          onClick={(e) => selectLebel(e.currentTarget)}
        >
          Not Very Active
          <p style={{ marginTop: "5px " }}>
            Spend most of the day sitting (e.g., bankteller, desk job)
          </p>
        </label>
        <input
          type="radio"
          name="activityLevel"
          id="activityLevel2"
          value="Lightly Active"
          onChange={(e) => setInputVal(e.target.value)}
        />
        <label
          htmlFor="activityLevel2"
          onClick={(e) => selectLebel(e.currentTarget)}
        >
          Lightly Active
          <p style={{ marginTop: "5px " }}>
            Spend a good part of the day on your feet (e.g., teacher,
            salesperson)
          </p>
        </label>
        <input
          type="radio"
          name="activityLevel"
          id="activityLevel3"
          value="Active"
          onChange={(e) => setInputVal(e.target.value)}
        />
        <label
          htmlFor="activityLevel3"
          onClick={(e) => selectLebel(e.currentTarget)}
        >
          Active
          <p style={{ marginTop: "5px " }}>
            Spend a good part of the day doing some physical activity (e.g.,
            food server, postal carrier)
          </p>
        </label>
        <input
          type="radio"
          name="activityLevel"
          id="activityLevel4"
          value="Very Active"
          onChange={(e) => setInputVal(e.target.value)}
        />
        <label
          htmlFor="activityLevel4"
          onClick={(e) => selectLebel(e.currentTarget)}
        >
          Very Active
          <p style={{ marginTop: "5px " }}>
            Spend a good part of the day doing heavy physical activity (e.g.,
            bike messenger, carpenter)
          </p>
        </label>
      </>

      {!document.querySelector(".getprogram input:checked") && flag && (
        <p className="error">
          please select one to create a good program for you
        </p>
      )}
      <div className="buttons">
        <button className="backbutton" onClick={() => changeStep(-1)}>
          Back
        </button>
        <button className="nextbutton" onClick={() => handelNextStep()}>
          NEXT
        </button>
      </div>
    </div>
  );
}

export default ActivityLevel;
