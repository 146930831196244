import React, { useContext, useEffect, useState } from "react";
import Paypal from "./payments/Paypal";
import Stripe from "./payments/Stripe";
import { formDataContext } from "./context/formDataContext";

function SelectPayment({ formData, changeStep, setLoading }) {
  const [goToPayError, setGoToPayError] = useState(false);
  const formDataContextNow = useContext(formDataContext);
  useEffect(() => {
    formDataContextNow.setFormData(formData);
    localStorage.setItem(
      "formData",
      JSON.stringify(formDataContextNow.formData)
    );
  }, [formDataContextNow.formData]);
  const pStyle = {
    opacity: "1",
    marginTop: "10px",
    fontSize: "16px",
    fontWeight: "600",
  };
  const weekNumber = () => {
    if (formData.weightCategory == "Obesity class 2") {
      return " 16 ";
    } else if (formData.weightCategory == "Obesity class 3") {
      return " 20 ";
    } else {
      return " 12 ";
    }
  };
  return (
    <div className="changeDiv">
      <h2>
        What You Get <b>{formData.name} ?</b>
      </h2>
      <ul
        style={{
          textAlign: "start",
          margin: "15px auto 20px",
          lineHeight: "1.8",
          fontWeight: "500",
          letterSpacing: ".3px",
          fontSize: "15px",
          listStyleType: "circle",
          listStylePosition: "inside",
        }}
      >
        <li style={{ marginTop: "10px" }}>
          📆 Program Duration ({weekNumber()} weeks)
        </li>
        <p>
          (Carefully structured to deliver visible results and a healthier you)
        </p>
        <li style={{ marginTop: "10px" }}>
          🏋️‍♀️ Personalized Dietary Guidance for You: <b>$49</b>
        </li>
        <li style={{ marginTop: "10px" }}>
          🎥 Customized Exercise Routine for You: <b>$157</b>
        </li>
        <li style={{ marginTop: "10px", marginBottom: "20px" }}>
          😴 Sleep and Stress Management Tips: <b>$59</b>
        </li>
        <p style={pStyle}>
          + TOTAL VALUE – <s style={{ opacity: ".8" }}>$265</s>
        </p>
        <p style={pStyle}>
          + Now For Just –{" "}
          <span
            style={{ color: "#33b933", fontWeight: "bold", fontSize: "17px" }}
          >
            $97
          </span>
        </p>
      </ul>
      <>
        {
          <Paypal
            formData={formData}
            setLoading={setLoading}
            setGoToPayError={setGoToPayError}
          />
        }
        {<Stripe />}
        {goToPayError && (
          <>
            <p className="error" style={{ marginTop: "16px" }}>
              Something went wrong. Please try again!
            </p>
            <p className="error">
              If you have any problems with the payment, please contact us at
              <a href="mailto:yassinazarguy12@gmail.com">
                <b style={{ color: "rgb(178, 12, 2)" }}>
                  {" "}
                  yassinazarguy12@gmail.com
                </b>
              </a>
              .
            </p>
          </>
        )}
      </>
      <div className="buttons">
        <button className="backbutton" onClick={() => changeStep(-1)}>
          Back
        </button>
      </div>
    </div>
  );
}

export default SelectPayment;
