import React from "react";
import "./onePayerCart.css";

export default function OnePayerCart({ title, theList }) {
  return (
    <div className="informationCard">
      <div className="title">
        <i class={title.iClass}></i>
        <span>{title.content}</span>
      </div>
      <table>
        {theList.map((oneList) => (
          <tr>
            <th>{oneList.content} :</th>
            <th>{oneList.value}</th>
          </tr>
        ))}
      </table>
    </div>
  );
}
