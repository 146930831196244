import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import FormDataProvider from "./pages/getprogram/context/formDataContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <FormDataProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </FormDataProvider>
);
