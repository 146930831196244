import React, { useEffect, useState } from "react";

function GetCalorie({ setFormData, formData, changeStep }) {
  const name = formData.name;
  const tall = getTall();
  const weight = getWeight();
  const BMI = BMICalculate();
  const [showDiv, setShowDiv] = useState(false);
  const [spanWeightStyle, setSpanWeightStyle] = useState({});
  const [motivationText, setMotivationText] = useState("");
  const [weightCategory, setWeightCategory] = useState("");

  const spanStyle = {
    textTransform: "capitalize",
    fontWeight: "bold",
    letterSpacing: ".7px",
    fontSize: "15px",
    marginLeft: "10px",
  };

  useEffect(() => {
    setTimeout(() => {
      setShowDiv(true);
    }, 1000);

    // Calculate the weight category and update the corresponding state
    const category = findWeightCategory();
    setWeightCategory(category);
    setFormData({
      ...formData,
      bmi: BMI,
      weightCategory: category,
    });
  }, [BMI]);
  function handelNextStep() {
    changeStep(1);
  }
  function getNumber(input) {
    return +input.split(" ")[0];
  }

  function getTall() {
    let GetNum = getNumber(formData.tall);
    return formData.tall.includes("cm") ? GetNum : GetNum * 2.54;
  }

  function getWeight() {
    let GetNum = getNumber(formData.weight);
    return formData.weight.includes("kg") ? GetNum : GetNum * 0.453592;
  }

  function BMICalculate() {
    return Math.round((weight / (tall * 0.01 * tall * 0.01)) * 100) / 100;
  }

  function findWeightCategory() {
    if (BMI < 18.5) {
      setSpanWeightStyle({ ...spanStyle, color: "orange" });
      setMotivationText(
        <>
          This may indicate that you are <b>underweight</b>. It’s important to
          ensure you're getting <b>a balanced diet</b> to provide your body with
          the nutrients it needs.
        </>
      );
      return "Underweight";
    } else if (BMI >= 18.5 && BMI < 25) {
      setSpanWeightStyle({ ...spanStyle, color: "#26ad26" });
      setMotivationText(
        <>
          Great job! Your weight is within <b>the normal range</b>, which
          suggests you’re taking good care of your health. Keep up the good work
          by maintaining a healthy and <b>balanced lifestyle</b>.
        </>
      );
      return "Normal weight";
    } else if (BMI >= 25 && BMI < 30) {
      setSpanWeightStyle({ ...spanStyle, color: "#e7c107" });
      setMotivationText(
        <>
          You might be in <b>the overweight category</b>. Don’t worry—this is a
          perfect opportunity to start making <b>positive changes</b>. With
          small adjustments to <b>your diet</b> and <b>exercise routine</b>, you
          can work towards <b>a healthier weight.</b>
        </>
      );
      return "Overweight";
    } else if (BMI >= 30 && BMI < 35) {
      setSpanWeightStyle({ ...spanStyle, color: "rgb(221 205 10)" });
      setMotivationText(
        <>
          You may fall into the <b>first category of obesity</b>. Now is the
          time to take serious steps toward improving <b>your health</b>. By
          putting in some effort and focusing on <b>positive changes</b>, you
          can make a <b>big difference in your life</b>.
        </>
      );
      return "Obesity class 1";
    } else if (BMI >= 35 && BMI < 40) {
      setSpanWeightStyle({ ...spanStyle, color: "rgb(231 110 7)" });
      setMotivationText(
        <>
          Don’t let the numbers <b>discourage you!</b> This means you may be in
          a stage where extra support from <b>health professionals</b> could be
          beneficial. Remember, every step you take towards healthier living is
          a step in <b>the right direction</b>.
        </>
      );
      return "Obesity class 2";
    } else {
      setSpanWeightStyle({ ...spanStyle, color: "red" });
      setMotivationText(
        <>
          Don’t let the numbers <b>discourage you!</b> This means you may be in
          a stage where extra support from <b>health professionals</b> could be
          beneficial. Remember, every step you take towards healthier living is
          a step in <b>the right direction</b>.
        </>
      );
      return "Obesity class 3";
    }
  }

  return (
    <div className="changeDiv">
      <h2>Calculate your BMI </h2>
      <p>
        We’re happy you’re here <span style={spanStyle}>{name}</span>!
      </p>
      {!showDiv ? (
        <p className="creatingBMI">Your BMI is being calculated...</p>
      ) : (
        <div>
          <ol
            style={{
              width: "400px",
              textAlign: "start",
              margin: "15px auto",
              lineHeight: "1.8",
              fontWeight: "500",
              letterSpacing: ".3px",
              fontSize: "15px",
            }}
          >
            <li>
              + Your Body Mass Index (BMI):
              <span style={spanStyle}>{BMI}</span>
            </li>
            <li>
              + Your Weight category:
              <span style={spanWeightStyle}>{weightCategory}</span>
            </li>
          </ol>
          <p style={{ marginTop: "10px" }}>{motivationText}</p>
          <div className="buttons">
            <button className="backbutton" onClick={() => changeStep(-1)}>
              Back
            </button>
            <button className="nextbutton" onClick={() => handelNextStep()}>
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default GetCalorie;
