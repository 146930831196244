import React, { useEffect, useState } from "react";

function Tall({ setFormData, formData, changeStep, selectLebel }) {
  const [flag, setFlag] = useState(false);
  function handelNextStep() {
    setFlag(true);
    if (document.querySelector(".getprogram input").value > 0) {
      const tall =
        document.querySelector(".getprogram input").value +
        " " +
        document.querySelector('.getprogram input[name="tallType"]:checked')
          .value;
      setFormData({
        ...formData,
        tall: tall,
      });
      changeStep(1);
    }
  }

  useEffect(() => {
    document.querySelector("input").focus();
  });
  return (
    <div className="changeDiv">
      <h2>4. What is your height {formData.name}?</h2>
      <p>
        we use it to calculate your <b>BMI</b>.
      </p>
      <>
        <input
          type="number"
          name="Tall"
          placeholder="Tall"
          className="smallInput"
        />
        <input type="radio" name="tallType" id="cm" value="cm" checked={true} />
        <label
          htmlFor="cm"
          className="smallLabel active"
          onClick={(e) => selectLebel(e.target)}
        >
          cm
        </label>
        <input type="radio" name="tallType" id="enches" value="enches" />
        <label
          htmlFor="enches"
          className="smallLabel"
          onClick={(e) => selectLebel(e.target)}
        >
          enches
        </label>
      </>
      {!(document.querySelector(".getprogram input").value > 0) && flag && (
        <p className="error">please enter a valide tall</p>
      )}
      <div className="buttons">
        <button className="backbutton" onClick={() => changeStep(-1)}>
          back
        </button>
        <button className="nextbutton" onClick={() => handelNextStep()}>
          NEXT
        </button>
      </div>
    </div>
  );
}

export default Tall;
