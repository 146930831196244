import React from "react";
import Header from "../../compenents/header/header";
import Footer from "../../compenents/footer/footer";
import { Link } from "react-router-dom";
import ShowSvg from "../../Accessts/shoe.svg";
import PaperSvg from "../../Accessts/paper.svg";
import BookSvg from "../../Accessts/book.svg";
import "./home.css";

function home() {
  return (
    <div className="home">
      <Header />
      <main>
        <div className="container">
          <h2>Good health starts with what you eat.</h2>
          <div className="content">
            <div className="description">
              <h1>Good health starts with what you eat.</h1>
              <p>
                Want to eat more mindfully? Track meals, learn about your
                habits, and reach your goals with MyFitnessPal, Want to eat more
                mindfully? Track meals, learn about your habits, and reach your
                goals with MyFitnessPal.
              </p>
              <button type="button">
                <Link to={"getprogram"}>make your program</Link>
              </button>
            </div>
            <div className="image">
              <img
                src={require("../../Accessts/fetness.webp")}
                alert="fetness foto"
              />
            </div>
          </div>
        </div>
      </main>
      <div className="tools">
        <div className="container">
          <div className="content">
            <h1>The Tools for Your Goals</h1>
            <hr />
            <p>
              Trying to lose weight, tone up, lower your BMI, or invest in your
              overall health? We give you the right features to get there.
            </p>
            <div className="section">
              <div className="con">
                <div className="icon">
                  <img src={ShowSvg} alt="" />
                </div>
                <h3>Learn. Track. Improve.</h3>
                <p>
                  Keeping a food diary helps you understand your habits and
                  increases your likelihood of hitting your goals.
                </p>
              </div>
              <div className="con">
                <div className="icon">
                  <img src={PaperSvg} alt="" />
                </div>
                <h3>Learn. Track. Improve.</h3>
                <p>
                  Keeping a food diary helps you understand your habits and
                  increases your likelihood of hitting your goals.
                </p>
              </div>
              <div className="con">
                <div className="icon">
                  <img src={BookSvg} alt="" />
                </div>
                <h3>Learn. Track. Improve.</h3>
                <p>
                  Keeping a food diary helps you understand your habits and
                  increases your likelihood of hitting your goals.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="gt_y_prgm">
        <img src="https://fitbod.me/wp-content/uploads/2021/12/Store.jpg" />
        <div className="parent">
          <div className="container">
            <div className="content">
              <h1>Keep your progress going with fitness gear that fits you.</h1>
              <hr />
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio,
                nam quaerat vero inventore repellendus libero? Culpa minima
                suscipit, rerum praesentium amet, dolor consectetur ratione
                omnis blanditiis temporibus quasi. Accusantium, hic?
              </p>
              <button type="button">
                <Link to={"getprogram"}>make your program</Link>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="stories">
        <div className="container">
          <div className="content">
            <div className="title">
              <h1>success story</h1>
              <hr />
            </div>
            <div className="cont">
              <div className="con">
                <img src="https://fitbod.me/wp-content/uploads/2021/04/Brian_Williams-300x300.jpeg" />
                <h3>Progress</h3>
                <p>
                  “I’m down 20lbs, body fat decreased by 20%, and finally got
                  off my blood pressure medications. It feels so good to take
                  this health journey and I’m very glad for the Fitbod app for
                  helping me move more.”
                </p>
                <div className="name">
                  <hr />
                  <span>John</span>
                </div>
              </div>
              <div className="con">
                <img src="	https://fitbod.me/wp-content/uploads/2021/04/John-Padgett.jpeg" />
                <h3>Confidence</h3>
                <p>
                  “I’m down 20lbs, body fat decreased by 20%, and finally got
                  off my blood pressure medications. It feels so good to take
                  this health journey and I’m very glad for the Fitbod app for
                  helping me move more.”
                </p>
                <div className="name">
                  <hr />
                  <span>John</span>
                </div>
              </div>
              <div className="con">
                <img src="https://fitbod.me/wp-content/uploads/2021/04/Meaghan-Bihun.jpeg" />
                <h3>Motivation</h3>
                <p>
                  “I’m down 20lbs, body fat decreased by 20%, and finally got
                  off my blood pressure medications. It feels so good to take
                  this health journey and I’m very glad for the Fitbod app for
                  helping me move more.”
                </p>
                <div className="name">
                  <hr />
                  <span>John</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="slider">
        <div className="container">
          <div
            className="content content1"
            style={{
              backgroundImage: `url(${require("../../Accessts/sliderprograms.jpg")})`,
            }}
          ></div>
          <div
            className="content content2"
            style={{
              backgroundImage: `url(${require("../../Accessts/sliderprograms.jpg")})`,
            }}
          ></div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default home;
