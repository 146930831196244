import React, { useEffect, useState } from "react";
import "./getprogram.css";
import Gender from "./questions/gender";
import Name from "./questions/name";
import Age from "./questions/age";
import Tall from "./questions/tall";
import Weight from "./questions/weight";
import GetAllInfo from "./questions/getAllInfo";
import GetCalorie from "./questions/getCalorie";
import Barriers from "./questions/barriers";
import ActivityLevel from "./questions/activityLevel";
import TimeHours from "./questions/timeHours";
import TriedBefore from "./questions/triedBefore";
import MedicalConditions from "./questions/medicalConditions";
import SleepHours from "./questions/sleepHours";
import FinishProgram from "./questions/finishPrpgram";
import GetEmail from "./getEmail";
import SelectPayment from "./selectPayment";
import HowIsProgram from "./howIsProgram";
import Loading from "./otherPages/loading";

function Getprogram() {
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  const selectLebel = (labelSelected) => {
    document.querySelectorAll(".getprogram label").forEach((oneLabel) => {
      oneLabel.classList.remove("active");
    });
    labelSelected.classList.add("active");
  };
  // change the step
  const changeStep = (a) => {
    setStep((prev) => prev + a);
  };

  return (
    <div className={step === 15 ? "getprogram full-screen" : "getprogram"}>
      <div className="container">
        {loading && <Loading />}

        <div className="content">
          <hr className="top" style={{ width: `${(step * 100) / 15}%` }} />
          {step <= 12 && (
            <h3 className="createProgram">
              creating your program <i className="fa-solid fa-gear"></i>
            </h3>
          )}

          {step == 0 && (
            <Gender
              selectLebel={selectLebel}
              setFormData={setFormData}
              formData={formData}
              changeStep={changeStep}
            />
          )}
          {step == 1 && (
            <Name
              setFormData={setFormData}
              formData={formData}
              changeStep={changeStep}
            />
          )}
          {step == 2 && (
            <Age
              setFormData={setFormData}
              formData={formData}
              changeStep={changeStep}
            />
          )}
          {step == 3 && (
            <Tall
              setFormData={setFormData}
              formData={formData}
              changeStep={changeStep}
              selectLebel={selectLebel}
            />
          )}
          {step == 4 && (
            <Weight
              setFormData={setFormData}
              formData={formData}
              changeStep={changeStep}
              selectLebel={selectLebel}
            />
          )}
          {step == 5 && (
            <GetAllInfo formData={formData} changeStep={changeStep} />
          )}
          {step == 6 && (
            <GetCalorie
              formData={formData}
              setFormData={setFormData}
              changeStep={changeStep}
            />
          )}
          {step == 7 && (
            <Barriers
              formData={formData}
              setFormData={setFormData}
              changeStep={changeStep}
              selectLebel={selectLebel}
            />
          )}
          {step == 8 && (
            <ActivityLevel
              changeStep={changeStep}
              formData={formData}
              setFormData={setFormData}
              selectLebel={selectLebel}
            />
          )}
          {step == 9 && (
            <TimeHours
              changeStep={changeStep}
              formData={formData}
              setFormData={setFormData}
              selectLebel={selectLebel}
            />
          )}
          {step == 10 && (
            <TriedBefore
              changeStep={changeStep}
              formData={formData}
              setFormData={setFormData}
              selectLebel={selectLebel}
            />
          )}
          {step == 11 && (
            <MedicalConditions
              changeStep={changeStep}
              formData={formData}
              setFormData={setFormData}
              selectLebel={selectLebel}
            />
          )}
          {step == 12 && (
            <SleepHours
              changeStep={changeStep}
              formData={formData}
              setFormData={setFormData}
              selectLebel={selectLebel}
            />
          )}
          {step == 13 && (
            <FinishProgram
              changeStep={changeStep}
              formData={formData}
              setFormData={setFormData}
            />
          )}
          {step == 14 && (
            <GetEmail
              setFormData={setFormData}
              formData={formData}
              changeStep={changeStep}
            />
          )}
          {step == 15 && (
            <HowIsProgram
              changeStep={changeStep}
              formData={formData}
              setFormData={setFormData}
            />
          )}
          {step == 16 && (
            <SelectPayment
              setFormData={setFormData}
              formData={formData}
              changeStep={changeStep}
              selectLebel={selectLebel}
              setLoading={setLoading}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Getprogram;
