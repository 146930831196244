export default function timeAgo({ dateString }) {
  const dateNow = new Date().getTime();
  const payerDate = new Date(dateString).getTime();
  const differenceDate = dateNow - payerDate;
  const second = Math.floor(differenceDate / 1000);
  const minutes = Math.floor(second / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const year = Math.floor(days / 365);
  if (second < 60) return "last " + second + " second ago";
  else if (minutes < 60) return "last " + minutes + " minutes ago";
  else if (hours < 24) return "last " + hours + " hours ago";
  else if (days < 30) return "last " + days + " days ago";
  else if (days < 365) return "last " + months + " months ago";
  else return "last " + year + " years ago";
}
